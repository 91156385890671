<template>
    <div>
        
        <div style="display: flex; align-self: center; justify-content: center;">
            <div id="drop-zone-pdf" class="drop-zone-pdf">
                <div class="item" v-for="item in getAllValuesListTwo" :key="item.id">
                    <div class="drag-el"
                        :style="{ position: 'absolute', top: calculateTop(item), left: calculateLeft(item) }">
                        {{ item.title }}
                    </div>
                </div>
            </div>
        </div>

        <div class="notes-pdf">
            <div style="padding-bottom: 16px;"><b>Eigene Notizen</b></div>
            <div v-for="value in getValueReflection" :key="value.id">
                <div v-if="value.note !== ''">
                    <div><b>{{ value.title }}</b></div>
                    <p>{{ value.note }}</p>
                </div>
            </div>
        </div>
   
   </div>
</template>

<script>

import Button from "../../../components/Button.vue"

export default {
    name: 'valuereflectionPDF',
    components: {
        Button
    },
    props: {
        isOverview: Boolean,
        isVisible: Boolean,
        isPDF: Boolean
    },
    computed: {

        showValueReflection() {
            return this.$store.getters['survey/getShowValueReflection']
        },
        getActiveValue() {
            return this.$store.getters['survey/getActiveValue']
        },
        getValueReflection() {
            return this.$store.getters['survey/getAllValues']
        },
        getAllValuesListTwo() {
            return this.$store.getters['survey/getAllValuesListTwo']
        },
        getAllValuesListThree() {
            return this.$store.getters['survey/getAllValuesListThree']
        },
        getAllValuesListOne() {
            return this.$store.getters['survey/getAllValuesListOne']
        },

    },
    methods: {
        calculateTop(item) {
            let newTop
            newTop = Math.round(557 / 100 * item.percentTop)

            return newTop + 'px'
        },
        calculateLeft(item) {
            let newLeft
            newLeft = Math.round(557 / 100 * item.percentLeft)

            return newLeft + 'px'
        },
        getValueReflectionList(list) {
            return this.$store.getters['survey/getAllValues'].filter((item) => item.list === list)

        },
        getValueReflectionItemIsActive(item) {
            return this.$store.getters['survey/getValueReflectionItemIsActive'](item)
        },



    },
    created() {

    },
    mounted() {
        this.dropZone = document.getElementById("drop-zone-pdf")

        this.$nextTick(() => {
            const width = this.dropZone.offsetWidth
            const height = this.dropZone.offsetHeight
            this.widthDropZone = width
            this.heightDropZone = height

        })


    },
    beforeUpdate() {
    },
    updated() {

    },
    beforeUnmount() {
    },
    unmounted() {

    },
    data() {
        return {
            images: {
                dart: require("@/assets/images/elsisat/Zielscheibe.svg"),
            },
            widthDropZone: 0,
            heightDropZone: 0,
            posTop: 0,
            posLeft: 0,
            dropZone: null,
        }
    },
}

</script>

<style lang="scss" scoped>
.drop-zone-pdf {
    aspect-ratio: 1;
    width: 75%;
    border-radius: 50%;
    background-image: url("~@/assets/images//elsisat/Zielscheibe.svg");
    background-size: cover;
    height: 75%;
    padding: 0;
    padding-bottom: calc(100% * 3 / 4);
    background-repeat: no-repeat;
    background-position: center center;
}

.item {
    position: absolute;
}

.drag-el {
    background: #fff;
    border-radius: 1rem;
    transition: all 0.3s ease-in-out;
    padding: 8px 20px 8px 20px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    text-align: center;
    cursor: move;
    /* fallback: no `url()` support or images disabled */
    cursor: -webkit-grab;
    /* Chrome 1-21, Safari 4+ */
    cursor: -moz-grab;
    /* Firefox 1.5-26 */
    cursor: grab;
    /* W3C standards syntax, should come least */

    &:hover {
        background: darken($elsi-color-blue, 8);
        color: white;
        text-align: center;
    }
}

.notes-pdf {
    padding-top: 32px;
    text-align: justify;
}
</style>
